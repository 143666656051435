<template>
  <div>
    <!--Slider Section Start-->
    <slider :without-hero="true"
            :show-quote="false"
            :images="[
        '/images/realtor.png',
        '/images/realtor.png',
        '/images/realtor.png',
        '/images/realtor.png',
        '/images/realtor.png',
    ]"></slider>

    <section class="position-relative content-realtor">
      <b-container class="my-5">
        <b-row>
          <b-col cols="12" class="block--headline">
            <div class="d-flex flex-column justify-content-center">
              <h1 class="jd-text-36 jd-text-30__mobile text-center">
                Offer your clients a seamless, stress-free moving experience!
              </h1>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">
             Enhanced Client Experience
            </span>
            <p class="jd-text-18 jd-text-16__mobile">
              Offer clients a one-stop solution for their moving, storage and junk removal needs, reducing stress and
              improving satisfaction.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">

            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">
              Priority & Flexible Scheduling
            </span>
            <p class="jd-text-18 jd-text-16__mobile">
              Ability to accommodate last-minutes moves, changes in schedule and priority booking, will ensure a
              smooth transition to your clients’ new home.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">
              Dedicated Support
              Tespan</span>
            <p class="jd-text-18 jd-text-16__mobile">
              Enjoy a dedicated contact person from our team to address your needs and those of your clients quickly
              and to ensure your clients have the best moving experience possible.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Customized Solutions
              </span>
            <p class="jd-text-18 jd-text-16__mobile">
              Get tailored, personalized services based on the unique needs of your clients, whether they require
              special handling for fragile items or moving baby grand piano into a climate controlled storage.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Exclusive Discounts
              fospanYour Clients</span>
            <p class="jd-text-18 jd-text-16__mobile">
              Provide your clients special rates and promotions when they book our services.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Increased Credibility
              & spanpeat Business</span>
            <p class="jd-text-18 jd-text-16__mobile">
              Happy clients are more likely to refer your services to friends and family. Aligning with a reputable
              moving company enhances your reputation as a knowledgeable and reliable Realtor.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Time-
              Saspanng Benefits</span>
            <p class="jd-text-18 jd-text-16__mobile">
              By outsourcing moving logistics, you can dedicate more time to your real estate business an client
              relationships
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Emergency Support
              </span>
            <p class="jd-text-18 jd-text-16__mobile">
              Access to emergency moving services for clients facing unexpected situations, helping you stand out as a
              responsive Realtor.
            </p>
          </b-col>

          <b-col cols="12" class="benefit--block">
            <span data-v-1668df3a="" class="jd-font-bold py-1 d-block jd-text-18 align-items-center d-flex">
              <img data-v-1668df3a="" src="/img/icons/arrow_left.svg" alt="" width="12px" class="mr-4">Financial Incentives
              </span>
            <p class="jd-text-18 jd-text-16__mobile">
              Earn $100 gift card for first 3 clients you refer to us!
            </p>
          </b-col>

        </b-row>
      </b-container>

    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import EmploymentForm from "@/components/EmploymentForm.vue";
import Slider from "@/views/_partials/Home/Slider.vue";
import JoinForm from "@/components/JoinForm.vue";

export default {
  components: {JoinForm, Slider},
  data() {
    return {}
  },
  mounted() {
    this.$root.$emit('hideLoader');
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.benefit--block {
  margin-bottom: 15px;

  .title {
    display: flex;
    align-items: center;
    &:before {
      content: "◯";
      display: block;
      margin-right: 8px;
      font-size: 14px;
      transform: translateY(-1px);
    }
  }
}
</style>
